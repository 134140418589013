<template>
  <!-- 应用策略管理 -->
  <div>
    <!-- 文字 -->
    <div class="text">
      <h4>应用策略管理报表</h4>
    </div>
    <!-- 新增按钮 -->
    <div class="onBtn">
      <el-button type="danger" style="margin-left: auto" @click="New">新增</el-button>
    </div>
    <!-- 内容区域 -->
    <div>
      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="tokenName" label="序号" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenEnglish" label="买入方式" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenNum" label="数字通证" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="资金指定账号" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="数字通证指定账号" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="循环挂单比例" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="状态" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="操作时间" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="操作人" width="" align="center"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">查看</el-button>
            <el-button size="mini" type="danger" @click="handleDelete">下线</el-button>
            <el-button size="mini" type="primary" @click="handleDelete">重新审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: '应用策略'
}
</script>

<style lang="scss" scoped>
.text {
  color: #409eff;
}
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 3px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>